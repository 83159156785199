.discount-prices-grid .k-grid {
  border-top: none;
  max-height: 300px;

  td,
  th {
    padding: 2px 0 1px 10px;
    font-size: 11px;
  }

  .k-grid-toolbar {
    background-color: #fafafa;
    border-top-style: 1px solid rgba(0, 0, 0, 0.08);
  }

  .k-grid-header {
    background-color: #ebebeb;
    color: #2f363d;

    tr {
      height: 26px;
      cursor: none;

      th {
        vertical-align: middle;
        padding: 0 5px 0 10px;
        font-size: 11px;
        font-weight: 600;
      }
    }
  }

  tr.k-master-row {
    .part-number {
      padding-left: 9px;
    }

    .supplier-description {
      padding-left: 9px;
      input {
        width: 300px !important;
      }
    }

    .standard {
      padding-left: 9px;
      input {
        width: 90px !important;
      }
    }

    .price-retail {
      padding-left: 0;

      input {
        padding: 3px 9px 5px 6px;
        border-left: 1px solid #cccccc;
      }
    }

    .discount-group-select {
      padding-left: 0;
    }

    .part-numbers-oe {
      padding-left: 0;
    }
  }

  tr.k-master-row {
    height: 26px;

    &.k-alt {
      background-color: #fafafa;
    }

    td {
      padding: 0;
      padding-left: 10px;
      font-size: 11px;

      button {
        padding: 4px 14px 2px 14px !important;
        font-size: 11px;
      }
    }

    .part-number {
      color: #707070;
      font-weight: 600;
      padding-left: 10px;
      input {
        width: 300px !important;
      }
    }

    .grid-commands {
      padding-left: 0;
      text-align: center;

      .commands-icon:nth-child(2) {
        margin-left: 5px;
      }
    }

    .commands-icon {
      color: #7d847d;
      visibility: hidden;

      &:hover {
        cursor: pointer;
        color: #2f363d;
      }
    }

    .commands-icon.static {
      visibility: visible;
    }

    &:hover .commands-icon {
      visibility: visible;
    }

    .discount-group-select {
      overflow: visible;

      .form-group {
        margin: 0;
      }
    }
  }

  .retail-price-cell {
    display: flex;
    padding-right: 10px;

    .amount {
      margin-left: auto;
    }
  }

  .add-discount-btn {
    font-size: 11px;
    padding: 6px 21px 4px 21px !important;

    &.btn.btn-primary,
    &.btn.btn-primary:focus,
    &.btn.btn-primary:active {
      background-color: #5c9cdc;

      &:hover {
        background-color: #468fd8;
      }
    }
  }
}
