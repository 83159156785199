.modal.fade {
  opacity: 0;
  transition: none;
}

.modal.fade.show {
  opacity: 1;
}

.modal.show .modal-dialog {
  top: 50%;
  transform: translateY(-50%);
  transition: 0.2s all ease-in;
}

.so-modal {
  max-width: 55%;
}

.modal-body .react-datepicker-wrapper,
.modal-body .react-datepicker__input-container {
  width: 100%;
}

.so-required-date {
  border: 1px solid #ccc !important;
  width: 100% !important;
  max-width: none;
  min-width: 250px;
  height: 45px;
}

.modal-body-padded {
  padding: 15px;
}

.modal-body-padded.fat {
  padding: 55px 15px;
}

.modal-item-padded-bottom {
  margin: 0 0 30px 0;
}

.images-modal,
.vin-modal,
.v2-modal {
  max-width: 45%;
  max-height: 100%;
  margin-top: 0px;
}

.vin-modal {
  max-width: 75% !important;
  width: 75%;
}

.modal-body-vin {
  height: 100%;
}

.vinInfoItem {
  font-size: 9pt;
  line-height: 1.25em;
}

.masonry-grid {
  display: flex;
  margin-left: -15px;
}

.masonry-grid_column {
  padding-left: 15px;
}

.masonry-grid_column .box-group {
  margin-bottom: 12px;
}

.masonry-grid_column .box-group .box-container {
  padding: 15px 15px 15px 15px;
}

.box-group {
  padding: 5px;
  margin-top: 15px;
}

.box-group h4 {
  position: absolute;
  top: -0.75em;
  background: white;
  padding: 0 10px;
  left: 10px;
  font-size: 1.25em;
}

.box-group .box-container {
  border: 1px solid #ddd;
  padding: 25px 20px 10px 20px;
  margin: 0;
  border-radius: 8px;
  position: relative;
}

.box-group .box-container.padded {
  padding: 1.5em 1.5em 1em 1.5em;
}

.image-gallery-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-gallery-fullscreen-button:hover::before,
.image-gallery-play-button:hover::before,
.image-gallery-left-nav:hover::before,
.image-gallery-right-nav:hover::before {
  color: #3d42f3 !important;
}

.image-gallery-thumbnail.active {
  border: 4px solid #3d42f3 !important;
}

.v2-modal {
  max-width: 750px;
}

.v2-modal .modal-title {
  font-size: 2em;
  line-height: 1em;
}

.v2-modal .modal-content {
  padding: 25px;
}

.v2-modal .modal-header,
.v2-modal .modal-footer {
  border: none;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #333;
}

.error-modal {
  max-width: 600px;
}

.email-modal {
  max-width: 1171px;
}

.email-preview-box {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 0 25px -10px #333;
  padding: 15px 10px;
  position: relative;
  margin-left: 5px;
}

.preview-badge {
  position: absolute;
  top: 0;
  right: 10px;
}

.preview-badge span {
  padding: 6px 8px;
}

.preview-email-header {
  color: #777;
  margin-bottom: 15px;
}

.email-badge {
  padding: 6px 8px;
  text-align: left;
  background: #3d42f3;
  max-width: 100%;
  white-space: initial;
  margin: 2px 0;
}

.email-preview-table {
  margin: 0;
  width: 100%;
}

td.email-preview-td {
  text-align: left;
}

.email-preview-table tr:last-child td {
  text-align: right;
}

td.email-preview-td:last-child {
  text-align: right;
}

.mbr-suggestion {
  width: 100%;
}

.modal-footer.flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.image-loading-indicator {
  font-size: 1.5em;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-height: 4px;
  margin: 0;

  .image-progress-bar {
    width: 100%;
  }
}

.scheduler {
  max-width: 100%;
  display: flex;

  .scheduler-flex {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .scheduler-switch {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;

      .form-group {
        margin: 0;
        display: flex;
        align-items: center;
      }

      .label {
        margin-left: 15px;
        display: inline-flex;
        align-items: center;
      }
    }
  }

  .cron_builder_container {
    .cron_builder {
      width: 100%;
      max-width: 100%;
      width: 500px;
      border: none;
      background: transparent;

      .nav.nav-tabs {
        z-index: 20;
        display: flex;
        background-color: white;

        li {
          padding: 10px 20px;
          border: none;
          margin-bottom: -2px;
          color: #fff;
          background: lighten(#3498ff, 20%);

          &.active {
            font-weight: bold;
            color: #fff;
            background: #3498ff;
          }
        }
      }

      .cron_builder_bordering {
        z-index: 10;
        border: none;

        .tab-pane {
          display: flex;
          flex-flow: column;

          .well {
            padding: 10px;
            font-size: 1em;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }

          > span {
            display: flex;
            align-items: center;
          }

          .hours,
          .minutes {
            display: inline-flex;
          }
        }
      }
    }

    .cron_schedules {
      display: flex;

      .cron-builder-bg {
        background: transparent;
        color: #111;
        text-align: left;
        margin: 20px;
        font-size: 1.25em;
      }

      .cron-value-label {
        font-weight: bold;
      }
    }
  }
}
