.app-checkbox-container {
  display: flex;
  justify-content: center;

  .checkbox {
    position: relative;
    cursor: pointer;
  }

  .checkbox > input {
    height: 14px;
    width: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid #34495e;
    outline: none;
    transition-duration: 0.3s;
    background-color: #ffffff;
    cursor: pointer;
  }

  .checkbox > input:checked {
    border: 1px solid #5c9cdc !important;
    background-color: #5c9cdc !important;
  }

  .checkbox > input {
    border: 1px solid #5c9cdc;
  }

  .checkbox > input[disabled],
  .checkbox > input[disabled]:checked {
    opacity: 0.5;
    cursor: default;
  }

  .checkbox > input:checked + span::before {
    content: 'L';
    display: block;
    text-align: center;
    color: #ffffff;
    position: absolute;
    left: 3px;
    top: 5px;
    font-weight: 700;
    font-size: 11px;
    transform: scale(-1, 1) rotate(-45deg);
  }
}
