.input-group .price-edit-field {
  border: solid 1px rgba(0, 0, 0, 0.08) !important;
  color: #656565;
  background: #fff;
  border-radius: 2px;
  margin: 0;
  outline: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.4285714286;
  box-sizing: border-box;
  display: inline-flex;
  flex-flow: row nowrap;
  vertical-align: middle;
  position: relative;
  -webkit-appearance: none;
  height: auto;
  padding: 4px 2px;
}

.input-group-text {
  line-height: 0;
  border: none;
}

.input-group {
  background: transparent;
}
