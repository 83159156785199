@import 'global';

.login-page {
  background-size: cover;
  background-position: 50% 50%;
  z-index: 10;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  background-color: #e9edf2;

  &::before {
    content: '';
    background: transparent url('../images/login_background.png');
    background-size: cover;
    position: absolute;
    background-repeat: no-repeat no-repeat;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.1;
  }
}

.login-container {
  width: 400px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 10px 0 #707070;
  z-index: 20;

  .login-header {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    background-color: #0567c9;
    font-size: 22px;
    color: #ffffff;
  }

  .loader {
    height: 55px;
    margin: 2px 0 0 0;
  }

  .form-groups {
    flex: 1;
    width: 100%;
    height: 180px;
    padding: 35px;

    .form-group {
      .input-group-prepend {
        position: absolute;
        height: 36px;
        padding: 0.25em 0.75em;
        line-height: 2.5;
        background: #0567c9;
        color: #ffffff;
        z-index: 4;
        border: 1px solid #5c9cdc;
      }

      input {
        height: 36px;
        font-size: 14px;
        padding: 5px 3px 1px 50px;
        border-color: #5c9cdc;
        background-color: #e9edf2;

        &::placeholder {
          color: #2f363d;
        }

        &:focus {
          box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
        }
      }

      margin-bottom: 0;

      &:first-of-type {
        margin-bottom: 25px;
      }
    }
  }

  .text-danger {
    margin-bottom: 25px;
    font-size: 12px;
    color: #fc6055;
    font-weight: 600;
  }

  .login-button,
  .login-button:focus,
  .login-button:active {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55px;
    font-size: 20px;
    background: #0567c9;
    color: #ffffff;
    border: none;
    padding: 0.75em 2em;

    i {
      margin-left: 5px;
    }

    &:hover {
      background: #0567c9;
    }

    &:disabled {
      opacity: 1;
    }
  }
}
