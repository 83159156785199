.ftpDetails {
  min-width: 890px;

  .required-star {
    font-size: 2em;
    color: red;
  }

  .modal-content {
    border-radius: 0;
    min-height: 420px;

    .modal-header {
      border: none;
      padding: 20px 0 10px 30px;
    }

    .modal-body {
      display: flex;
      padding-left: 30px;

      .rs-steps {
        padding: 10px 20px 0 0;
        margin-right: 20px;
        border-right: 1px dashed #89b8e6;

        .rs-steps-item {
          padding-bottom: 30px;
        }

        .rs-steps-item-status-process .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
          border-color: #5c9cdc;
          background: #5c9cdc;
        }

        .rs-steps-item-icon {
          padding-top: 1px;
        }
      }

      .rs-panel {
        flex: 1;
      }

      .rs-panel-body {
        padding: 0 20px 0 10px;
      }

      .rs-steps-item:not(.rs-steps-item-status-process) {
        cursor: pointer;
      }

      .field-mapping {
        input {
          max-width: 200px;

          &::placeholder {
            color: #d2cfcc;
          }

          .not-valid {
            border: 2px solid #fc6055;
            padding: 2px;
          }
        }
      }

      .form-group {
        display: flex;
        margin-bottom: 10px;
        position: relative;
        min-height: 26px;

        label {
          min-width: 170px;
          display: flex;
          align-items: center;

          i {
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 4px;
          }
        }

        input {
          box-shadow: none;
          min-height: 26px;
          height: 26px;

          &.not-valid {
            border: 2px solid #fc6055;
          }

          &[type='checkbox'] {
            height: 14px;
            width: 14px;
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            appearance: none;
            border: 1px solid #ccc;
            outline: none;
            transition-duration: 0.3s;
            background-color: #fff;
            cursor: pointer;
            min-height: 0;
            margin-top: 0;
            margin-left: 0;
            position: relative;

            &:checked {
              background: #ccc;
            }
          }
        }

        .confirm-not-valid input {
          border: 1px solid #fc6055;
          box-shadow: 0 0 0 1px #fc6055;
        }

        .form-group,
        .confirm-valid,
        .confirm-not-valid {
          width: 100%;
        }

        .form-group {
          margin-bottom: 0;
        }
      }

      .full-width-select {
        min-width: 142px;
        width: 100%;
      }
    }

    .modal-footer {
      padding: 0 32px 30px 0;
      border-top: none;

      button {
        font-size: 11px;
        padding: 6px 21px 4px 21px !important;
      }

      .btn.btn-primary,
      .btn.btn-primary:focus,
      .btn.btn-primary:active {
        background-color: #5c9cdc;

        &:hover {
          background-color: #468fd8;
        }
      }

      .btn.btn-secondary,
      .btn.btn-secondary:focus,
      .btn.btn-secondary:active {
        background-color: #e9edf2;
        color: #4b4c4e;

        &:hover {
          background-color: #d8e0e8;
        }
      }

      .load-more-balls {
        width: 82.5px !important;
        height: 25px;
      }
    }
  }

  .badge {
    display: inline-block;
    padding: 4px 0.4em 3px 0.4em;
    min-width: 37px;
    font-size: 10px;
    font-weight: normal;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .badge-primary {
    background-color: #5c9cdc !important;
  }

  .badge-secondary {
    background-color: #e9edf2 !important;
    color: #000000 !important;
  }

  .table {
    thead,
    tbody {
      display: block;
    }

    tbody {
      max-height: 290px;
      overflow-y: auto;
    }

    tr:hover {
      background-color: #f7f7f7;
    }

    th,
    td {
      border-width: 1px;
    }

    td {
      vertical-align: middle;
      padding: 5px 12px;
    }

    th.col-start,
    td.col-start {
      max-width: 220px;
      min-width: 220px;
    }

    th.col-end,
    td.col-end {
      max-width: 220px;
      min-width: 220px;
    }

    th.col-success,
    td.col-success {
      max-width: 100px;
      min-width: 100px;
      text-align: center;
    }

    div.col-no-data {
      text-align: center;
      padding: 40px;
    }
  }
}
