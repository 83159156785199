.sidebar-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #e9edf2;

  .sidebar-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .sidebar-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      min-height: 35px;
      padding: 5px 0 5px 20px !important;
      font-size: 16px;
      font-weight: normal !important;
      line-height: 1;
      color: #2f363d !important;
      background-color: transparent !important;
      border: 0 !important;
      box-shadow: none !important;
      transition: none;
      text-align: left;
      outline: none;

      span {
        word-break: break-all;
      }

      i {
        padding: 5px 10px;
        color: #fc6055;
      }

      &.active {
        background-color: #ffffff !important;
        color: #0567c9 !important;
      }

      &:hover {
        background-color: #d8dfe9 !important;
      }
    }
  }

  .sidebar-button-container {
    display: flex;

    .refresh-pricefile {
      display: flex;
      align-items: center;
      padding: 5px 10px;
      cursor: pointer;

      .uploading-icon,
      .failed-upload-icon {
        color: #fc6055;
      }

      &:hover:not(.uploading) {
        background-color: #d8dfe9 !important;
      }
    }

    .sidebar-button:hover ~ .refresh-pricefile {
      background-color: #d8dfe9 !important;
    }

    .sidebar-button.active ~ .refresh-pricefile {
      background-color: #ffffff;

      &:hover {
        background-color: #d8dfe9 !important;
      }
    }
  }

  .bulk-update {
    text-align: center;
    margin-bottom: 15px;

    button {
      width: 132px;
      font-size: 11px;
      padding: 6px 21px 4px 21px !important;
      border: none !important;
    }

    .btn.btn-primary,
    .btn.btn-primary:focus,
    .btn.btn-primary:active {
      background-color: #5c9cdc;

      &:hover {
        background-color: #468fd8;
      }
    }
  }

  .sidebar-bottom {
    padding-bottom: 15px;

    .version {
      color: #2f363d;
      font-size: 8px;
      opacity: 0.8;
      text-align: center;
    }
  }
}
