.price-file-update-modal .modal-content {
  width: 450px;
  border-radius: 0;

  .modal-body {
    .title {
      margin-bottom: 10px;
      font-size: 20px;
    }

    .additional-content {
      font-size: 16px;
      margin-bottom: 10px;
      color: #fc6055;
    }

    .content {
      font-size: 16px;
    }
  }

  .modal-footer {
    padding-top: 0;
    border-top: none;

    button {
      font-size: 11px;
      background-color: #2f363d;
      padding: 6px 21px 4px 21px !important;
    }

    .btn.btn-primary,
    .btn.btn-primary:focus,
    .btn.btn-primary:active {
      background-color: #5c9cdc;

      &:hover {
        background-color: #468fd8;
      }
    }

    .btn.btn-secondary,
    .btn.btn-secondary:focus,
    .btn.btn-secondary:active {
      background-color: #e9edf2;
      color: #4b4c4e;

      &:hover {
        background-color: #d8e0e8;
      }
    }
  }
}
