@import './variables.scss';

.list-parts {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 45px);
}

.no-data {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.list-parts .k-grid {
  border: none;

  td,
  th {
    padding: 2px 0 1px 10px;
    font-size: 11px;
  }

  .k-grid-header {
    background-color: #5c9cdc;
    color: #ffffff;

    tr {
      height: 36px;

      th {
        vertical-align: middle;
        padding: 0 5px 0 10px;
        font-size: 14px;
        font-weight: 600;

        a {
          cursor: initial;
        }
      }
    }
  }

  tr.k-master-row.k-grid-edit-row {
    .part-number {
      padding-left: 9px;
    }

    .supplier-description {
      padding-left: 9px;

      input {
        width: 300px !important;
      }
    }

    .standard {
      padding-left: 9px;

      input {
        width: 90px !important;
      }
    }

    .price-retail {
      padding-left: 0;
    }

    .discount-group-select {
      padding-left: 0;
    }

    .part-numbers-oe {
      padding-left: 0;
    }
  }

  tr.k-master-row {
    height: 26px;

    &.k-alt {
      background-color: #fafafa;
    }

    &:hover {
      background-color: #e9edf2;
    }

    td {
      padding: 0;
      padding-left: 10px;
      font-size: 11px;

      button {
        padding: 4px 14px 2px 14px !important;
        font-size: 11px;
      }
    }

    .part-number {
      color: #707070;
      font-weight: 600;
      padding-left: 10px;

      input {
        width: 200px !important;
      }
    }

    .grid-commands {
      padding-left: 0;
      text-align: center;

      .commands-icon:nth-child(2) {
        margin-left: 5px;
      }
    }

    .commands-icon {
      color: #7d847d;
      visibility: hidden;

      &:hover {
        cursor: pointer;
        color: #2f363d;
      }
    }

    .commands-icon.static {
      visibility: visible;
    }

    &:hover .commands-icon {
      visibility: visible;
    }

    .discount-group-select {
      overflow: visible;

      .form-group {
        margin: 0;
      }
    }
  }

  .retail-price-cell {
    display: flex;
    padding-right: 10px;

    .amount {
      margin-left: auto;
    }
  }
}

.loading-priceFile-info {
  color: #2f363d;

  .lpi-icon {
    margin-bottom: 25px;
  }

  .lpi-label {
    margin-bottom: 10px;
    font-size: 22px;
  }

  .lpi-time {
    font-size: 18px;
  }
}
