@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-image-gallery/styles/scss/image-gallery.scss';
// @import "~react-phone-number-input/style.css";
@import './variables.scss';
@import './Animations.scss';
// @import "./Inputs.scss";
// @import "./Toasts.scss";
@import './Modal.scss';
@import './checkbox.scss';

body {
  background: $mainBackground;
  font-family: Spartan;
  color: #707070;
  font-size: 11px;
}

//

*::-webkit-scrollbar-track {
  background-color: #cee1f4;
}

*::-webkit-scrollbar {
  width: 3px;
  height: 5px;
  background-color: #cee1f4;
}

*::-webkit-scrollbar-thumb {
  background-color: #0567c9;
}

//

*::selection {
  background: #fc6055;
  color: #ffffff;
}

*::-moz-selection {
  background: #fc6055;
  color: #ffffff;
}

//

.fill {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.flex {
    display: flex;

    &.center {
      align-items: center;
      justify-content: center;
    }
  }
}

.display-block {
  display: block;
}

.no-margin {
  margin: 0;
}

.padded-container {
  padding: 30px;
}

button {
  border-radius: 5px;
}

@function inputFrameColor($color, $amount) {
  @return lighten($color, $amount);
}

@mixin formBorder($color, $amount: 70%) {
  border-color: inputFrameColor($color, $amount);
}

.form-control,
.DateInput_input,
.google-places-autocomplete__input,
.PhoneInputInput {
  border-radius: 0;
  width: 100%;
  box-shadow: none;
  padding: 3px;
  border: 1px solid hsl(0, 0%, 80%);
  font-size: 1em;

  &:disabled {
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
  }
}

.select-dropdown {
  div {
    @include formBorder($secondary);

    &:hover {
      @include formBorder($secondary, 60%);
    }

    svg {
      fill: inputFrameColor($secondary, 60%);
    }
  }
}

.PhoneInput {
  box-shadow: none !important;
  @include formBorder($secondary);

  .phone-country-dropdown {
    border-radius: 5px 0 0 5px !important;

    > div {
      @include formBorder($secondary);
      border-radius: 5px 0 0 5px !important;

      svg {
        fill: inputFrameColor($secondary, 60%);
      }

      > div:first-child {
        min-width: 70px;
      }
    }
  }

  .PhoneInputInput {
    border-radius: 0 5px 5px 0;
    box-shadow: none !important;
    @include formBorder($secondary);
    border-left: none;
  }
}

.input-group {
  background: white;

  .input-group-text {
    background: transparent;
    padding-left: 1em;
    padding-right: 1em;
    position: relative;
    @include formBorder($secondary);

    &::after {
      content: '';
      height: 80%;
      position: absolute;
      right: 0;
      top: 10%;
      width: 1px;
      background: lighten($secondary, 70%);
    }
  }

  &.disabled {
    .input-group-prepend {
      background: #e9ecef !important;
    }
  }

  // input {
  //   border-left: none;
  // }
}

@mixin buttonBaseDarker($color) {
  // border-style: solid;
  // border-color: darken($color, 10%) !important;
  // border-width: 0 0 1px 0;
  // background-color: $color;
  outline: none !important;
  box-shadow: none !important;

  border: none;
}

@mixin buttonBaseLighter($color) {
  // border-style: solid;
  // border-color: ligten($color, 10%) !important;
  // border-width: 0 0 1px 0;
  // background-color: $color;
  outline: none !important;
  box-shadow: none !important;

  border: none;
}

@mixin buttonDarker($color, $amount) {
  @include buttonBaseDarker($color);

  &:focus {
    @include buttonBaseDarker($color);
  }

  &:hover {
    background: darken($color, $amount);
  }

  &:active {
    background: darken($color, $amount + 2);
    border-bottom-color: $color !important;
  }

  &:disabled {
    border-bottom-color: transparent !important;
  }
}

@mixin buttonLighter($color, $amount) {
  @include buttonBaseLighter($color);

  &:focus {
    @include buttonBaseLighter($color);
  }

  &:hover {
    background: lighten($color, $amount);
  }

  &:active {
    background: lighten($color, $amount + 2);
    border-bottom-color: $color !important;
  }

  &:disabled {
    border-bottom-color: transparent !important;
  }
}

@mixin buttonOutlineBase($color) {
  border-style: solid;
  border-color: $color !important;
  border-width: 1px;
  background-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
  color: $color !important;

  &:hover {
    background-color: $color !important;
    color: white !important;
  }
}

@mixin buttonOutline($color, $amount) {
  @include buttonOutlineBase($color);
}

button {
  border-radius: 0 !important;
  font-weight: 700 !important;
  padding: 0.75em 2.5em;

  &.btn-primary {
    @include buttonDarker($primary, 5%);
  }

  &.btn-secondary {
    @include buttonDarker($secondary, 5%);
  }

  &.btn-outline-secondary {
    @include buttonOutline($secondary, 5%);
  }

  &:disabled {
    pointer-events: none;
  }
}

.google-places-autocomplete__suggestions-container {
  background: #fff;
  margin-top: 5px;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 0 5px -3px rgba(0, 0, 0, 0.2);

  .google-places-autocomplete__suggestion {
    padding: 10px 10px;

    &:hover {
      background: lighten($secondary, 75%);
      cursor: pointer;
    }
  }
}

label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}

.field-limit-indicator {
  color: #ccc;
  font-size: 0.85em;
  text-align: right;
  float: right;
}

.btn.no-margin-v {
  margin-top: 0;
  margin-bottom: 0;
}

.logout-button {
  padding: 0 15px !important;
  background: transparent !important;
  color: $brandSecondary;
  border: none !important;
  font-weight: normal !important;

  &:hover {
    color: $brandPrimary;
  }
}

.relative {
  position: relative;
}

.btn {
  font-size: 14px;
  padding: 0.5em 1.5em !important;
}

.load-more {
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.loading-text {
  font-size: 14px;
  font-weight: 600;
  color: #707070;
}

//

.infinite-scroll-component {
  overflow-x: hidden !important;
}

.loader {
  .loader-inner.ball-pulse-sync div {
    background-color: #5c9cdc;
  }
}

// risky approach..
.cstm-input {
  > div:nth-of-type(1) > div:nth-child(1) {
    padding: 0 6px;

    > div {
      margin: 1px;
      height: 20px;
      display: flex;
      align-items: center;

      &:nth-of-type(2) {
        padding: 0;
      }
    }

    &:hover {
      cursor: text;
    }
  }

  > div div:nth-child(2) > div {
    padding: 2px 2px 2px 0px;
  }
}

.cstm-input.app-multitext {
  > span {
    box-shadow: none;
  }

  > div {
    border-radius: 0;
    min-height: 26px;
    box-shadow: 0 0 0 1px #cccccc;
  }

  > div:nth-of-type(1) {
    box-shadow: 0 0 0 0 #cccccc;
    border-color: #cccccc;
  }

  &.not-valid {
    > div:nth-of-type(1) {
      border: 2px solid #fc6055;
    }

    > div div:nth-child(2) > div {
      padding: 1px 0px 1px 0px;
    }
  }

  > div:nth-of-type(1) > div:nth-child(1) {
    padding: 0 6px;

    > div {
      margin: 1px;
      height: 20px;
      display: flex;
      align-items: center;

      &:nth-of-type(2) {
        padding: 0;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  > div div:nth-child(2) > div {
    padding: 2px 2px 2px 0px;

    &.not-valid {
      padding: 1px 2px 1px 0px;
    }
  }
}

//

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
