.parts-filter-form {
  margin: 40px 20px 0 20px;

  .form-group {
    margin-bottom: 10px;
  }

  label {
    font-size: 14px;
    line-height: 1.7;
    margin-bottom: 2px;
    color: #2f363d;
  }

  input {
    width: 100%;
    height: 26px;
    padding-left: 8px;
    padding-right: 4px;
    border: 1px solid #cccccc;

    &:focus {
      outline: none;
    }
  }

  .filter-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    .clear {
      margin-left: auto;
    }
  }

  button {
    padding: 5px 12px 3px 12px !important;
    color: #ffffff;
    border: none !important;
    font-size: 11px;

    &:focus {
      outline: none;
    }
  }

  .btn.btn-primary,
  .btn.btn-primary:focus,
  .btn.btn-primary:active {
    background-color: #5c9cdc;

    &:hover {
      background-color: #468fd8;
    }
  }

  .btn.btn-secondary,
  .btn.btn-secondary:focus,
  .btn.btn-secondary:active {
    background-color: #bfcbd9;
    color: #4b4c4e;

    &:hover {
      background-color: #a6b6c9;
    }
  }
}