@import './global.scss';
@import './variables.scss';

.app-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  margin-top: 45px;

  .app-sidebar {
    position: relative;
    height: 100%;
    min-width: 250px;
    max-width: 250px;
  }

  .app-main {
    position: relative;
    background: #ffffff;
    width: calc(100vw - 250px);
    padding: 0;
  }
}
