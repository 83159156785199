.supplierInfo {
  min-width: 800px;

  .modal-content {
    border-radius: 0;
    min-height: 450px;

    .modal-header {
      border: none;
      padding: 20px 0 10px 30px;
    }

    .modal-body {
      padding-left: 30px;

      > div {
        display: flex;

        .rs-steps {
          padding: 10px 20px 20px 0;
          margin-right: 20px;
          border-right: 1px dashed #89b8e6;

          .rs-steps-item {
            padding-bottom: 20px;
          }

          .rs-steps-item-status-process
            .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
            border-color: #5c9cdc;
            background: #5c9cdc;
          }

          .rs-steps-item-icon {
            padding-top: 1px;
          }
        }

        .rs-panel {
          flex: 1;
        }

        .rs-panel-body {
          padding: 0 20px 0 10px;
        }

        .rs-panel {
          flex: 1;

          * {
            word-wrap: break-word;
          }
        }
      }

      .card {
        background: transparent;
        background-color: transparent;
        border: none;

        .card-header {
          margin-bottom: 5px;
          border: none;
          background: transparent;
          font-size: 1.25em;
          font-weight: bold;
          padding: 0;
          font-size: 12px;
        }

        .card-body {
          padding: 5px 15px;
          margin-bottom: 5px;

          .card-item {
            margin-bottom: 5px;
            display: flex;
          }

          .card-item-label {
            min-width: 120px;
            display: flex;
            align-items: center;
          }

          .badge.badge-secondary {
            margin: 1px;
            padding: 4px;
          }
        }
      }

      .discount-lists {
        display: flex;

        .card-body {
          padding: 5px 25px 5px 0;
        }
      }
    }

    .modal-footer {
      padding: 0 32px 30px 0;
      border-top: none;

      button {
        font-size: 11px;
        padding: 6px 21px 4px 21px !important;
      }

      .btn.btn-primary,
      .btn.btn-primary:focus,
      .btn.btn-primary:active {
        background-color: #5c9cdc;

        &:hover {
          background-color: #468fd8;
        }
      }

      .btn.btn-secondary,
      .btn.btn-secondary:focus,
      .btn.btn-secondary:active {
        background-color: #e9edf2;
        color: #4b4c4e;

        &:hover {
          background-color: #d8e0e8;
        }
      }
    }
  }

  .rs-steps-item {
    cursor: pointer;
    opacity: 0.7;
    transition: 0.1s opacity ease-in;
  }

  .rs-steps-item:hover {
    opacity: 1;
  }

  .rs-steps-item-status-finish,
  .rs-steps-item-status-wait {
    .rs-steps-item-icon {
      color: #aaa;
    }

    .rs-steps-item-tail {
      border-color: #aaa;
      opacity: 0;
    }
  }

  .rs-steps-item-status-process {
    opacity: 1;

    .rs-steps-item-tail {
      border-color: #aaa;
      opacity: 0;
    }
  }

  .center-align {
    display: flex;
    justify-content: center;
    align-content: center;
  }
}
