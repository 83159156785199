.delete-part-modal .modal-content {
  width: 400px;

  .modal-body {
    .title {
      margin-bottom: 10px;
      font-size: 14px;
    }
    
    .content {
      font-size: 11px;
    }
  }
  
  .modal-footer {
    padding-top: 0;
    border-top: none;

    button {
      font-size: 11px;
      background-color: #2f363d;
      padding: 6px 21px 4px 21px !important;
    }
  }
}
