.main-header {
  height: 45px;
  display: flex;
  align-items: center;
  background-color: #0567c9;
  font-size: 18px;
  color: #ffffff;

  .header-left {
    width: 170px;
    text-align: center;
    font-weight: 600;
  }

  .header-right {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .logout {
    margin-left: 5px;
    padding: 0 15px 0 20px;
    cursor: pointer;
    line-height: 2.5;

    &:hover {
      background-color: #055cb3;
    }
  }
}
