.load-more-balls.small {
  transform: scale(0.5);

  .loader {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .loader-inner div {
    background-color: #5c9cdc;
  }
}
